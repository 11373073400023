<template>
  <div class="page-list-content admin_container_default">
    <div class="page-list-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            質問管理
          </div>
        </div>
        <!--<div class="row-input col-8">
          <input
            class="form-control w-100"
            v-model="keywords"
            placeholder="検索"
            v-on:keyup="getListChatByAdmins()"
          />
        </div> -->
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button
            v-on:click="gotoDashboard()"
            class="button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row px-3 py-3 d-flex">
        <div class="row-input m-0 cpx-4 w-50 my-2">
          <input
            class="form-control col-sm-12"
            v-model="keywords"
            style="height: 35px"
            placeholder="検索"
            v-on:keyup="getListChatByAdmins()"
          />
        </div>
      </div>
      <div class="row cpx-4">
        <div class="col-6 m-0 d-flex col-6 align-items-center px-2">
          <label class="col-3 font-weight-bold">投稿日時</label>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="fromTimePost"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListChatByAdmins()"
            ></date-picker>
          </div>
          <div class="col-1 p-0 text-center" style="font-size: 20px">~</div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="toTimePost"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListChatByAdmins()"
            ></date-picker>
          </div>
        </div>
        <div class="col-6 m-0 d-flex col-6 align-items-center px-2">
          <label class="col-3 font-weight-bold">最終更新日時</label>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="fromTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListChatByAdmins()"
            ></date-picker>
          </div>
          <div class="col-1 text-center" style="font-size: 20px">~</div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="toTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListChatByAdmins()"
            ></date-picker>
          </div>
        </div>
      </div>
      <Tables
        :items="ListChatByAdmin.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-15"
      >
        <template v-slot:name="{ item }">
          <td v-if="item.name">{{ item.name }}</td>
          <td v-else>{{ item.email }}</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              item.created_at &&
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              item.updated_at &&
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:chapter="{ item }">
          <td v-if="item.chapter">{{ item.chapter }}</td>
        </template>
        <!-- <template v-slot:message="{ item }">
          <td>
            <div class="customTextTable">{{ item.message }}</div>
          </td>
        </template> -->
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId
                  ? 'Detail Chat Admin'
                  : 'Detail Chat Admin domain',
                params: {
                  id: item.id,
                },
              }"
            >
              <CButton
                class="mx-1 text-nowrap btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                style="background-color: #2eb85c; color: white"
                v-b-modal.modal-comfirm
              >
                編集
              </CButton>
            </router-link>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="ListChatByAdmin.total"
      >
        <p class="font-weight-bold">
          {{ ListChatByAdmin.total }}件中{{
            ListChatByAdmin.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="ListChatByAdmin.total"
      >
        <CPagination
          v-if="ListChatByAdmin.total"
          :activePage.sync="page"
          :pages="ListChatByAdmin.last_page"
          align="center"
        />
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeCategory(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-showContent" hide-header hide-footer>
        <div class="d-flex justify-content-between" style="padding: 20px">
          <div class="col-sm-4 d-flex align-items-center">
            <span> コンテンツ: </span>
          </div>
          <div class="col-sm-8">
            <span v-for="(item, index) in dataContent" :key="index"
              >{{ item.text }},&ensp;
            </span>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "ListChatByAdmin",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.CHAT_BY_ADMIN,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataContent: [],
      limit: 50,
      page: 1,
      // shop_id: null,
      keywords: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      fromTimePost: "",
      toTimePost: "",
      fromTimeEdit: "",
      toTimeEdit: "",
    };
  },
  created() {
    this.getListChatByAdmins(1);
  },
  computed: {
    ...mapGetters(["message", "success", "error", "ListChatByAdmin"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListCate(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListChatByAdmin() {
      this.page = this.ListChatByAdmin.current_page;
    },
    page() {
      this.getListChatByAdmins(this.page);
    },
  },
  methods: {
    ...mapActions({ getListChatByAdmin: "getListChatByAdmin" }),
    ...mapActions({ deleteCategory: "deleteCategory" }),
    getListChatByAdmins(page) {
      if (
        (this.fromTimePost &&
          this.toTimePost &&
          this.fromTimePost > this.toTimePost) ||
        (this.fromTimeEdit &&
          this.toTimeEdit &&
          this.fromTimeEdit > this.toTimeEdit)
      ) {
        this.$toasted.error("該当日時の検索はできません。");
      } else {
        if (page === undefined) {
          page = this.page;
        }
        const formData = {
          page: page,
          data: {
            shop_id: this.shop_id,
            keywords: this.keywords,
            post_start_date: this.fromTimePost,
            post_end_date: this.toTimePost,
            update_start_date: this.fromTimeEdit,
            update_end_date: this.toTimeEdit,
            limit: this.limit,
          },
        };
        this.getListChatByAdmin(formData);
      }
    },
    createCategory() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Category"
          : "Create Category domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async showConfirmContent(id) {
      this.dataModal = id;
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      await this.getCategoryById(data);
    },
    async removeCategory(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteCategory", data);
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-page");
          this.getListCate(this.page);
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editCategory(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Category"
          : "Edit Category domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListCate(1);
    },
    gotoDashboard() {
      this.$router.go(-1);
    },
    resetFieldSearch() {
      this.keywords = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.customTextTable {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-list-content {
  padding: 40px 4% !important;
}
</style>
